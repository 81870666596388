import { h } from "preact";
import Router from "preact-router";
import { Provider } from "@urql/preact";
import style from "../style/style.css";
import classNames from "classnames/bind";

// Code-splitting is automated for `routes` directory
import Viewer from "../routes/viewer";
import ContractViewer from "../routes/contract-viewer";
import TokenViewer from "../routes/token-viewer";
import KeyBoardInputCatch from "./key-board-input-catch";
import UserViewer from "../routes/user-viewer";
import FavoritesViewer from "../routes/favorites-viewer";
import RandomViewer from "../routes/random-viewer";
import { createPublicClient } from "../utils";

const cx = classNames.bind(style);

const client = createPublicClient();

const App = () => (
  <Provider value={client}>
    <KeyBoardInputCatch cx={cx}>
      <Router>
        <Viewer cx={cx} path="/" />
        <TokenViewer cx={cx} path="/token/:tokenId" />
        <RandomViewer cx={cx} path="/random/:contractAddress/:projectId?" />
        <ContractViewer cx={cx} path="/:contractAddress/:projectId?" />
        <UserViewer cx={cx} path="/user/:userAddress" />
        <FavoritesViewer cx={cx} path="/favorites/:favoriteType" />
      </Router>
    </KeyBoardInputCatch>
  </Provider>
);

export default App;
