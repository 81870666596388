import { h } from "preact";
import { useState } from "preact/hooks";
import InfoModal from "../info-modal";

const KeyBoardInputCatch = ({ cx, children }) => {
  const [infoModalVisible, setInfoModalVisible] = useState(false);
  if (typeof window !== "undefined") {
    window.addEventListener("keydown", (event) => {
      if (event.shiftKey) {
        switch (event.key) {
          case "I":
            setInfoModalVisible(!infoModalVisible);
            break;
          default:
            break;
        }
      }
      if (event.key === "Escape") {
        setInfoModalVisible(false);
      }
    });
  }

  return (
    <div id="app" className={cx("absolute-fill", "center-content")}>
      <InfoModal
        cx={cx}
        visible={infoModalVisible}
        setVisible={setInfoModalVisible}
      />
      {children}
    </div>
  );
};

export default KeyBoardInputCatch;
