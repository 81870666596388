import { h } from "preact";

const Modal = ({ cx, children, visible, setVisible }) => {
  return (
    <>
      {visible ? (
        <div
          onClick={() => setVisible(false)}
          className={cx("absolute-fill", "center-content", "modal")}
          style={{ zIndex: 1000 }}
        >
          <div
            onClick={(event) => {
              event.stopPropagation();
            }}
            className={cx("modal-container")}
          >
            <div className={cx("modal-button-align")}>
              <button
                className={cx("modal-close-button")}
                onClick={() => setVisible(false)}
              >
                x
              </button>
            </div>
            {children}
          </div>
        </div>
      ) : null}
    </>
  );
};

export default Modal;
