import { ethers } from "ethers";
import {
  createClient,
  defaultExchanges,
  subscriptionExchange,
} from "@urql/preact";
import { createClient as createWSClient } from "graphql-ws";

export const getProvider = () => {
  return new ethers.providers.AlchemyWebSocketProvider(
    process.env.NETWORK,
    process.env.ALCHEMY_KEY
  );
};

export const isValidHexColor = (str) => {
  return /[0-9A-F]{6}$/i.test(str);
};

export const createPublicClient = () => {
  const wsClient =
    typeof window !== "undefined"
      ? createWSClient({
          url: process.env.WSS_URL,
        })
      : null;

  const client = createClient({
    url: process.env.GRAPHQL_API_ENDPOINT,
    fetch: fetch,
    exchanges: [
      ...defaultExchanges,
      subscriptionExchange({
        forwardSubscription(operation) {
          return {
            subscribe: (sink) => {
              const dispose = wsClient.subscribe(operation, sink);
              return {
                unsubscribe: dispose,
              };
            },
          };
        },
      }),
    ],
  });

  return client;
};
